.weather-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: all 0.3s ease;
}

.game-info {
    text-align: center;
    margin-bottom: 20px;
}

.game-time {
    color: #666;
    margin: 5px 0;
}

.teams {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px 0;
}

.team {
    flex: 1;
}

.vs {
    font-weight: bold;
    color: #666;
}

.location {
    color: #666;
    font-size: 0.9em;
}

.weather-info {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.temperature {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 15px;
}

.weather-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    text-align: left;
}

.weather-details p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #444;
}

.snow-alert {
    position: relative;
    border: 3px solid #007bff;
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
}

.snow-indicator {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: white;
    padding: 4px 15px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.9em;
    white-space: nowrap;
    animation: pulse 2s infinite;
}

.snowflake {
    margin-left: 10px;
    font-size: 1.5em;
    animation: bounce 2s infinite;
}

.snow-conditions {
    color: #007bff;
    font-weight: bold;
}

@keyframes pulse {
    0% {
        transform: translateX(-50%) scale(1);
    }

    50% {
        transform: translateX(-50%) scale(1.05);
    }

    100% {
        transform: translateX(-50%) scale(1);
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

/* Weather Type Specific Styles */
.snow-weather {
    border: 3px solid #007bff;
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
}

.rain-weather {
    border: 3px solid #6c757d;
    background: linear-gradient(rgba(236, 240, 241, 0.9), rgba(236, 240, 241, 0.9));
}

.freezing-weather {
    border: 3px solid #17a2b8;
    background: linear-gradient(rgba(236, 248, 250, 0.9), rgba(236, 248, 250, 0.9));
}

.hot-weather {
    border: 3px solid #dc3545;
    background: linear-gradient(rgba(253, 236, 234, 0.9), rgba(253, 236, 234, 0.9));
}

.clear-weather {
    border: 3px solid #ffc107;
    background: linear-gradient(rgba(255, 248, 225, 0.9), rgba(255, 248, 225, 0.9));
}

.cloudy-weather {
    border: 3px solid #6c757d;
    background: linear-gradient(rgba(245, 245, 245, 0.9), rgba(245, 245, 245, 0.9));
}

/* Weather Indicators */
.weather-indicator {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 15px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 0.9em;
    white-space: nowrap;
    animation: pulse 2s infinite;
    color: white;
}

.snow-indicator {
    background-color: #007bff;
}

.rain-indicator {
    background-color: #6c757d;
}

.freezing-indicator {
    background-color: #17a2b8;
}

.hot-indicator {
    background-color: #dc3545;
}

.clear-indicator {
    background-color: #ffc107;
    color: black;
}

.cloudy-indicator {
    background-color: #6c757d;
}

/* Weather Conditions Text */
.snow-conditions {
    color: #007bff;
}

.rain-conditions {
    color: #6c757d;
}

.freezing-conditions {
    color: #17a2b8;
}

.hot-conditions {
    color: #dc3545;
}

.clear-conditions {
    color: #ffc107;
}

.cloudy-conditions {
    color: #6c757d;
}

.weather-icon {
    margin-left: 10px;
    font-size: 1.5em;
    animation: bounce 2s infinite;
}